import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

export default function FourOhFourPage() {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found | Rocky Brands Inc.</title>
      </Helmet>
      <Layout>
        <h1>404 - Page Not Found</h1>
        <p>This page does not exist.</p>
      </Layout>
    </>
  );
}
